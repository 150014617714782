
/* 公共样式表css */
$fontSize: 16;
@function px2rem($px) {
    @return ($px/$fontSize) + rem;
}
html,
body {
    color: #333;
    margin: 0;
    height: 100%;
    font-family: 'Myriad Set Pro', 'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;
}

img {
    border: 0;
}

body {
    background: #fff;
    color: #666;
    font: (px2rem(12))/384% Arial, Verdana, 'microsoft yahei';
}

html,
body,
div,
dl,
dt,
dd,
ol,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
button,
fieldset,
form,
input,
legend,
textarea,
th,
td {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    // color: #08acee;
}

button {
    outline: 0;
}

img {
    border: 0;
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font: inherit;
    color: inherit;
    outline: none;
}

li {
    list-style: none;
}

.clearfix::after {
    clear: both;
    content: '.';
    display: block;
    height: 0;
    visibility: hidden;
}

.clearfix {
}

/* 必要布局样式css */
.ato-content {
    width: 1138px;

    margin: 0 auto;
    padding-top: 13px;
}
.ato-list-fl {
    position: relative;
}
.ato-list-fl .prew {
    position: absolute;
    left: 0;
}
.ato-list-fl .next {
    position: absolute;
    right: 0;
}

.ato-list-fl a {
    width: 40px;
    height: 120px;
    position: relative;
    margin-top: 97px;
    float: left;
    display: block;
    overflow: hidden;
}

.ato-list-fl a img {
    width: 30px;
    height: 52px;
    display: block;
    border: none;
    margin: 0 auto;
    
    // margin-top: 12px;
}
.ato-list-fl a.next img {
    transform: rotate(180deg);
}

.ato-list ul li {
    /* margin-top:30px; */
    // width: 232px;
    // width: 154px;
    height: 275px;
    width: 154px;

    
    float: left;
    margin-left: 16px;
    margin-right: 0;
    text-align: center;
    // background-color: #F2F9F5;
    -webkit-transition: height 300ms;
    -moz-transition: height 300ms;
    -ms-transition: height 300ms;
    -o-transition: height 300ms;
    transition: height 300ms;
    position: relative;
    top: px2rem(0);
    left: -px2rem(10);
    cursor: pointer;
}

.ato-list ul li:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    /* box-shadow: 0 0px 2px rgba(0,0,0,0.24); */
}

.ato-list ul li div {
    // width: 232px;
    width: 154px;
    height: 100%;
    text-align: center;
    // background: url(../../assets/img/worldHistory/pbg.png);
    background-size: 100% 100%;
    position: relative;
}

.ato-list ul li div img {
    width: 154px;
    height: 206px;
    border: none;
    display: block;
    margin: 0 auto;
    cursor: pointer;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 20px;
    box-shadow: 0px 2px 6px 0px rgba(15, 11, 5, 0.29);
    // border: 1px solid #cccccc;
    // box-shadow: 0px 4px 6px 0px #808080;
}
.ato-list ul li div span {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 184px;
    width: 196px;
    box-sizing: border-box;
    font-size: 22px;
    font-family: Songti SC;
    font-weight: 400;
    color: #46587b;
    line-height: 22px;
    cursor: pointer;
}
.ato-list ul li div p {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 244px;
    width: 196px;
    text-align: center;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 22px;
    padding: 0;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; // 控制多行的行数
    -webkit-box-orient: vertical;
}

.ato-list ul li h3 {
    text-align: center;
    font-size: px2rem(17);
    line-height: px2rem(30);
    font-weight: normal;
    color: #333;
    font-weight: 500;
}

.ato-list ul li p {
    margin: 0;
    text-align: left;
    font-size: px2rem(13);
    color: #8e8e8e;
    line-height: px2rem(25);
    padding: 0 px2rem(22) px2rem(20) px2rem(22);
}

.ato-title {
    width: px2rem(266);
    margin-top: px2rem(130);
    padding-right: px2rem(20);
}

.ato-title h2 {
    font-size: px2rem(24);
    line-height: px2rem(33);
    color: #333;
    padding: 0;
    margin: 0;
    font-weight: inherit;
}

.ato-title p {
    margin-top: px2rem(10);
    font-size: px2rem(14);
    color: #333;
    line-height: px2rem(26);
}

.ato-list {
    // width: 1160px;
    width: 1038px;
    height: 275px;
    // height: 384px;

    // margin: 0 auto;
    margin: 0px 51px;
    overflow: hidden;
    margin-bottom: px2rem(10);
}

.ato-list ul li:hover .ato-logo001 {
    top: -px2rem(5);
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}
.more {
    margin: 20px 0;
    // text-align: right;
    // a {
    //     color: #595956;
    //     font-size: 12px;
    // }
    // a:hover {
    //     color: #46587b;
    // }
}
