
.institution-page {
    .institution-wrap {
        padding: 10px 30px 0;
        box-sizing: border-box;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
        .institution {
            display: flex;
            margin-top: 21px;
            .institution-img {
                margin-right: 30px;
                margin-bottom: 25px;
                img {
                    width: 145px;
                    min-height: 188px;
                    box-shadow: 0px 3px 9px 0px rgba(15, 11, 5, 0.29);
                }
            }
            .institution-info {
                h5 {
                    margin-bottom: 18px;
                    span {
                        font-size: 22px;
                        line-height: 22px;
                        padding-bottom: 2px;
                        border-bottom: 3px solid #bd1a2d;
                        cursor: pointer;
                    }
                }
                .content-institution {
                    color: #666;
                    line-height: 28px;
                    font-size: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 5; // 控制多行的行数
                    -webkit-box-orient: vertical;
                }
            }
        }
        .institution-chart {
            width: 1138px;
            height: 140px;
            border: 1px solid #e6e6e6;
            margin-bottom: 30px;
            background: url(../assets/img/databg.jpg);
            background-size: 100% 100%;
            background-position: 0 0;
            display: flex;
            li {
                display: flex;
                width: 569px;
                padding-left: 154px;
                .img-div {
                    margin-top: 28px;
                    margin-right: 19px;
                    img {
                        width: 79px;
                        height: 79px;
                    }
                }
                .content-div {
                    h5 {
                        color: #fff;
                        font-size: 24px;
                        margin-top: 12px;
                        span {
                            color: #fff;
                            font-size: 56px;
                        }
                    }
                    h6 {
                        font-size: 17px;
                        color: #fff;
                        // margin-left: 33px;
                    }
                }
            }
        }
    }
    .expert-wrap {
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
        padding: 10px 30px 0;
        height: 361px;
        margin-top: 25px;
    }
    .article-wrap {
        padding: 10px 30px 0;
        margin: 26px auto;
        box-sizing: border-box;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
    }
    .book-wrap {
        padding: 10px 30px 0;
        margin-bottom: 26px;
        box-sizing: border-box;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
    }
    .relevant-ins-wrap {
        margin-bottom: 30px;

        .relevant-ins {
            padding-top: 14px;
            display: flex;
            // justify-content: space-between;
            .relevant-ins-img {
                margin-right: 13px;
                img {
                    min-width: 106px;
                    height: 142px;

                    cursor: pointer;
                    box-shadow: 0px 2px 6px 0px rgba(15, 11, 5, 0.29);
                }
            }
            .relevant-ins-info {
                h5 {
                    font-size: 18px;
                    line-height: 18px;
                    margin-bottom: 8px;
                    cursor: pointer;
                }
                p {
                    color: #666;
                    line-height: 28px;
                    font-size: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 4; // 控制多行的行数
                    -webkit-box-orient: vertical;
                }
            }
        }
        ul {
            display: flex;
            flex-wrap: wrap;
            li {
                width: 231px;
                box-sizing: border-box;
                padding-left: 10px;
                position: relative;
                font-size: 18px;
            }
            li::before {
                content: '';
                width: 4px;
                height: 4px;
                position: absolute;
                left: 0;
                top: 12px;
                background: #ffac13;
            }
        }
    }
    .com-relevant {
        width: 1200px;
        height: 303px;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        padding: 10px 20px 0;
        ul {
            display: flex;
            flex-wrap: wrap;
            margin-top: 8px;
            li {
                margin-bottom: 15px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                cursor: pointer;
            }
        }
    }
}
