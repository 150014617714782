
.collection-detail-page {
    .collection-detail-wrap {
        display: flex;
        justify-content: space-between;
    }
    .com-title {
        height: 45px;
        line-height: 45px;
        border-bottom: 2px solid #dedede;
        display: flex;
        align-items: center;
        justify-content: space-between;
        div {
            display: flex;
            align-items: center;

            img {
                width: 24px;
                height: 24px;
                margin-right: 11px;
                margin-left: 3px;
            }
            h5 {
                height: 45px;
                line-height: 45px;
                font-size: 24px;
                color: #000000;
                border-bottom: 3px solid #bd1a2d;
            }
        }

        ul {
            display: flex;
            li {
                font-size: 18px;
                margin-right: 18px;
                cursor: pointer;
                line-height: 36px;
                color: #333;
            }
        }
        span {
            font-size: 14px;
            color: #404040;
            cursor: pointer;
            img {
                width: 4px;
                height: 8px;
                margin-left: 10px;
            }
        }
    }
    .left-collection-detail {
        width: 830px;
    }
    .w100 {
        width: 100%;
    }
    .left-collection-detail-wrap {
        .left-collection {
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            margin-bottom: 24px;
            box-sizing: border-box;
            padding: 28px;
            min-height: 700px;
            .top-info-left {
                display: flex;
                .top-info-left-wrap {
                    width: 204px;
                    height: 272px;
                    // box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
                    box-shadow: 0px 2px 6px 0px rgba(15, 11, 5, 0.29);
                    margin-right: 30px;
                    img {
                        width: 204px;
                        height: 272px;
                    }
                }

                .top-info {
                    flex: 1;
                    h5 {
                        margin-bottom: 15px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        width: 540px;
                        span {
                            font-size: 24px;
                            height: 39px;
                            border-bottom: 3px solid #bd1a2d;
                        }
                    }
                    p {
                        line-height: 37px;
                        font-size: 17px;
                        color: #666;

                        span {
                            display: inline-block;
                            font-size: 17px;
                            color: #333;
                        }
                    }
                    button {
                        width: 90px;
                        height: 30px;
                        background: #ff8600;
                        font-size: 14px;
                        color: #fff;
                        margin-right: 20px;
                        border: none;
                        border-radius: 4px;
                        margin-top: 9px;
                    }
                }
            }
            .author-info {
                div {
                    div {
                        h6 {
                            line-height: 40px;
                            font-size: 20px;
                        }
                        p {
                            line-height: 26px;
                            font-size: 16px;
                        }
                    }
                }
            }
            .com-div {
                h5 {
                    margin: 20px 0 14px;
                    span {
                        color: #333;
                        font-size: 24px;
                        height: 37px;
                        box-sizing: border-box;
                        border-bottom: 2px solid #bd1a2d;
                    }
                }
                p {
                    line-height: 26px;
                    font-size: 16px;
                }
            }
            .catalog {
                .catalog-content {
                    display: flex;
                    .left-catalog {
                        margin-right: 18px;
                        li {
                            width: 120px;
                            height: 36px;
                            box-sizing: border-box;
                            line-height: 36px;
                            color: #666;
                            font-size: 16px;
                            border: 1px solid #e6e6e6;
                            text-align: center;
                            cursor: pointer;
                            a {
                                display: block;
                                line-height: 36px;
                                color: #666;
                                p {
                                    line-height: 36px;
                                }
                            }
                        }
                        li:hover {
                            a {
                                background: #bd1a2d;
                                color: #fff;
                                p {
                                    background: #bd1a2d;
                                    color: #fff;
                                }
                            }
                        }
                        li.active {
                            a {
                                background: #bd1a2d;
                                color: #fff;
                                p {
                                    background: #bd1a2d;
                                    color: #fff;
                                }
                            }
                        }
                    }
                    ul.right-catalog {
                        flex: 1;
                        li:first-child {
                            h5 {
                                margin-top: 0px;
                            }
                        }
                        li {
                            h5 {
                                font-size: 16px;
                                color: #333;
                                line-height: 38px;
                                font-weight: bold;
                                margin: 20px 0 8px;
                            }
                            div.bot-wrap {
                                display: flex;

                                align-items: center;
                                position: relative;
                                line-height: 38px;
                                justify-content: space-between;
                                background: url(../assets/img/border.png);
                                background-position: 6% 49%;
                                background-repeat: repeat-x;
                                background-size: 1% 1px;
                                margin-bottom: 12px;
                                div {
                                    display: flex;
                                    padding-left: 6px;
                                    font-size: 16px;
                                    color: #333;
                                    background: #fff;
                                    padding-right: 7px;
                                    cursor: pointer;
                                    p {
                                        font-size: 16px;
                                        color: #333;
                                    }
                                }
                                .small-p {
                                    max-width: 397px;
                                }
                                .big-p {
                                    max-width: 525px;
                                }
                                a {
                                    font-size: 16px;
                                    color: #666;
                                    background: #fff;
                                    padding-left: 5px;
                                    display: block;
                                    line-height: 26px;
                                    cursor: auto;
                                }
                                .small-p >>> p .role3 {
                                    /* 4补字 3行内 */
                                    display: inline-block;
                                    max-height: 18px;
                                }
                                .small-p >>> p .role4 {
                                    vertical-align: middle;
                                    width: 16px !important;
                                    height: 16px !important;
                                    margin-top: -2px;
                                }
                            }
                            div.bot-wrap::before {
                                content: '';
                                width: 4px;
                                height: 4px;
                                border-radius: 50%;
                                background: #ffac13;
                                position: absolute;
                                left: -4px;
                                top: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
    .right-collection-detail {
        width: 316px;

        .relevant-anthology-wrap {
            padding: 0 18px;
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            margin-bottom: 24px;
            .com-title {
                div {
                    img {
                        height: 29px;
                    }
                }
            }
            .relevant-anthology {
                display: flex;
                justify-content: space-between;
                padding-top: 14px;
                img {
                    width: 106px;
                    height: 142px;
                    margin-bottom: 10px;
                }
                .relevant-anthology-main {
                    width: 162px;
                    h5,
                    p:nth-child(2) {
                        width: 162px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    h5 {
                        font-size: 16px;
                        margin-bottom: 10px;
                        cursor: pointer;
                    }
                    p {
                        font-size: 14px;
                        color: #666;
                        line-height: 22px;
                    }
                    p:nth-child(3) {
                        width: 162px;
                        text-overflow: -o-ellipsis-lastline;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 4;
                        line-clamp: 4;
                        -webkit-box-orient: vertical;
                    }
                }
            }
            ul {
                margin-bottom: 13px;
                li {
                    width: 278px;
                    box-sizing: border-box;
                    padding-left: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    line-height: 36px;
                    height: 36px;
                    font-size: 16px;
                    color: #333333;
                    cursor: pointer;
                    position: relative;
                }
                li::before {
                    position: absolute;
                    left: 0;
                    top: 18px;
                    content: '';
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background: #ffac13;
                }
            }
        }
        .relevant-article-wrap {
            padding: 0 18px;
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            margin-bottom: 24px;
            .com-title {
                div {
                    img {
                        height: 29px;
                    }
                }
            }
            .relevant-article {
                ul {
                    padding: 10px 0 14px;
                    li {
                        line-height: 37px;
                        height: 37px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        padding-left: 10px;
                        position: relative;
                        font-size: 16px;
                        color: #333;
                        width: 278px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        cursor: pointer;
                        box-sizing: border-box;
                    }
                    li::before {
                        content: '';
                        width: 4px;
                        height: 4px;
                        border-radius: 50%;
                        background: #ffac13;
                        position: absolute;
                        left: 0;
                        top: 18px;
                    }
                }
            }
        }
    }
}
